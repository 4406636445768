import React, { Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import ProtectedRoute from "./utils/ProtectedRoute";
import { LoadingProvider, useLoading } from "./contexts/LoadingContent";
import { ToastContainer } from "react-toastify";
import LoadingSpinner from "./components/LoadingSpinner";
import SearchData from "./components/News";
import Layout from "./components/layoutWithNews";
import NotFoundPage from "./components/error/404";
import { post } from "./services/apiService";
import { apiConstant } from "./services/apiConfig";


const Dashboard = lazy(() => import("./pages/dashboard"));
const SignIn = lazy(() => import("./pages/auth/sign-in"));
const SignUp = lazy(() => import("./pages/auth/sign-up"));
const SignOut = lazy(() => import("./pages/auth/sign-out"));
const ForgotPassword = lazy(() => import("./pages/auth/forgot-password"));
const ChangePassword = lazy(() => import("./pages/auth/change-password"));
const AdvanceSearch = lazy(() => import("./pages/search/advance"));
const QuickSearch = lazy(() => import("./pages/search/quick"));
const ColumnSearch = lazy(() => import("./pages/search/column"));
const NotificationSearch = lazy(() => import("./pages/search/notification"));
const DetailView = lazy(() => import("./pages/newsDetailLayout"));


const App: React.FC = () => { 


 

  return (
    <>
      <Suspense
        fallback={
          <div>
            <LoadingSpinner />
          </div>
        }
      >
        <AuthProvider>
          <LoadingProvider>
            <Routes>
              <Route path="/sign-in" element={<SignIn />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/" element={<Dashboard />} />
              
              <Route element={<ProtectedRoute />}>
                {/* Auth Routes  */}
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/logout" element={<SignOut />} />

                {/* Search Routes */}
                <Route
                  path="/tiolsearch/caselawadvancesearch"
                  element={<AdvanceSearch />}
                />
                <Route
                  path="/tiolsearch/caselawquicksearch"
                  element={<QuickSearch />}
                />
                <Route
                  path="/tiolsearch/columnsearch"
                  element={<ColumnSearch />}
                />
                <Route
                  path="/tiolsearch/notifications_circulars"
                  element={<NotificationSearch />}
                />

                {/* dynamic Routes */}
                <Route
                  path="/:parent"
                  element={
                    <Layout>
                      <SearchData isSearch={false} />
                    </Layout>
                  }
                />
                <Route
                  path="/:parent/:submenu1/:submenu2/:submenu3/details"
                  element={<DetailView />}
                />
                <Route
                  path="/:parent/:submenu1/:submenu2/:submenu3"
                  element={
                    <Layout>
                      <SearchData isSearch={false} />
                    </Layout>
                  }
                />
                <Route
                  path="/:parent/:submenu1/:submenu2/details"
                  element={<DetailView />}
                />
                <Route
                  path="/:parent/:submenu1/:submenu2"
                  element={
                    <Layout>
                      <SearchData isSearch={false} />
                    </Layout>
                  }
                />
                <Route
                  path="/:parent/:submenu1/details"
                  element={<DetailView />}
                />
                <Route
                  path="/:parent/:submenu1"
                  element={
                    <Layout>
                      <SearchData isSearch={false} />
                    </Layout>
                  }
                />

                <Route
                  path="/column/:submenu1"
                  element={
                    <Layout>
                      <SearchData isSearch={false} />
                    </Layout>
                  }
                />
              </Route>
              <Route path="/:parent/details" element={<DetailView />} />
              <Route path="/tiolinfo/:submenu1" element={<DetailView />} />
              <Route
                path="/tiolinfo/:submenu1/:submenu2"
                element={<DetailView />}
              />
            </Routes>
          </LoadingProvider>
        </AuthProvider>
      </Suspense>
    </>
  );
};

export default App;
