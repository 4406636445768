import React, { Fragment, useEffect, useRef, useState, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import tiol_logo from "../assets/images/tiol_logo.png";
import { commonStrings, homeSection } from "../services/stringConstant";
import MainNav from "./NavBar";
import { LuMenuSquare } from "react-icons/lu";
import MobileNav from "./MobileNav";
import { User } from "../types/user.types";
import { MenuType, SubMenuItem } from "../types/menuType.types";
import { getApi } from "../services/apiService";
import { apiConstant } from "../services/apiConfig";
import { subscribe } from "diagnostics_channel";

const MainHeader: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isSubscriptionMenuOpen, setIsSubscriptionMenuOpen] = useState(false);
  const [userData, setUserData] = useState<User>();
  const [tiolInfoList, setTiolInfoList] = useState<MenuType>();
  const token = window.localStorage.getItem("token") || false;
  const menuRef = useRef<HTMLDivElement>(null);
  const menuSubscriptionRef = useRef<HTMLDivElement>(null);
  const menuUserRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (token) {
      const data: string | null = window.localStorage.getItem("loginsession");
      data && setUserData(JSON.parse(data));
    }
  }, [token]);

  // Consolidated click outside handler using useCallback
  const handleClickOutside = useCallback((event: MouseEvent, ref: React.RefObject<HTMLDivElement>, setter: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setter(false);
    }
  }, []);

  // One useEffect for all dropdowns
  useEffect(() => {
    const menuListener = (event: MouseEvent) => handleClickOutside(event, menuRef, setIsMenuOpen);
    const subscriptionListener = (event: MouseEvent) => handleClickOutside(event, menuSubscriptionRef, setIsSubscriptionMenuOpen);
    const userListener = (event: MouseEvent) => handleClickOutside(event, menuUserRef, setIsUserMenuOpen);
    
    // Add listeners based on which menu is open
    if (isMenuOpen) document.addEventListener("mousedown", menuListener);
    if (isSubscriptionMenuOpen) document.addEventListener("mousedown", subscriptionListener);
    if (isUserMenuOpen) document.addEventListener("mousedown", userListener);
    
    // Cleanup function to remove all listeners
    return () => {
      document.removeEventListener("mousedown", menuListener);
      document.removeEventListener("mousedown", subscriptionListener);
      document.removeEventListener("mousedown", userListener);
    };
  }, [isMenuOpen, isSubscriptionMenuOpen, isUserMenuOpen, handleClickOutside]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  const toggleSubscrptionMenu = () => {
    setIsSubscriptionMenuOpen(!isSubscriptionMenuOpen);
  };
  
  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  useEffect(() => {
    getApi(apiConstant.getFooterList)
      .then((res) => {
        const infoList = res.filter(
          (item: MenuType) => item.title === commonStrings.TIOL_INFO
        );
        setTiolInfoList(infoList[0]);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);
  return (
    <div className="border-b">
      <header className="border-b border-orangered">
        <div className="mx-auto container">
          <div className="grid md:grid-cols-12 gap-2">
            <div className="col-span-6 mb-4 flex md:justify-normal justify-center">
              <div className="relative h-[4.7rem]">
                <img
                  onClick={() => {
                    navigate("/");
                  }}
                  src={tiol_logo}
                  alt="logo"
                  className="md:w-11/12 my-4  cursor-pointer"
                />
              </div>
            </div>
            {/* Right section with buttons */}
            <div className="col-span-6 flex justify-center md:justify-end md:h-[3rem]  relative md:top-[2.7rem] ">
              <div className="flex space-x-1">
                {token && (
                  <>
                    <div ref={menuUserRef}>
                      <button
                        className=" text-orangered font-normal text-sm h-10 mb-2 py-4 px-4 rounded-t-md focus:outline-none flex uppercase"
                        onClick={() => {
                          toggleUserMenu();
                        }}
                      >
                        <svg
                          className="w-5 h-5 mr-1 "
                          viewBox="0 0 24 24"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path
                            fill="currentColor"
                            d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4m0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4"
                          />
                        </svg>
                        <div className="sm:block hidden">
                          {userData?.userName}{" "}
                          {userData?.noOfAccess === 99999
                            ? "[UNLIMITED]"
                            : `[${userData?.accessCosumed} OF ${userData?.noOfAccess}]`}
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          className="sm:block hidden"
                        >
                          <path fill="currentColor" d="M12 16l-8-8h16z" />
                        </svg>
                      </button>
                      {isUserMenuOpen && (
                        <div className="absolute bg-white shadow-md mt-[0.1rem] rounded-md py-2 w-max px-3 z-50 right-[8rem]">
                          <div className="block sm:hidden p-3">
                            {userData?.userName} [{userData?.accessCosumed} OF{" "}
                            {userData?.noOfAccess}]
                          </div>
                          {homeSection.userMenuSettings.map((setting) => (
                            <div className="border-t p-3" key={setting.value}>
                              <Link to={setting.value} className="uppercase">
                                {setting.name}
                              </Link>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </>
                )}
                {!token && (
                  <>
                    <button
                      onClick={() => {
                        navigate("/sign-in");
                      }}
                      className={`text-orangered font-normal text-sm  my-5 px-2  focus:outline-none flex uppercase  ${
                        location.pathname === "/sign-in" ? "hidden" : "block"
                      }`}
                    >
                      <svg
                        className="w-5 h-5 mr-1"
                        viewBox="0 0 24 24"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path
                          fill="currentColor"
                          d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4m0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4"
                        />
                      </svg>
                      <div className="hidden md:block">sign in</div>
                    </button>
                    <div
                      ref={menuSubscriptionRef}
                      className={`${
                        location.pathname === "/sign-in" ||
                        location.pathname === "/sign-up" ||
                        location.pathname === "/forgot-password"
                          ? "hidden"
                          : "block"
                      }`}
                    >
                      <button
                        className=" text-orangered font-normal text-sm my-5 px-2 pr-4 rounded-t-md focus:outline-none flex uppercase"
                        onClick={() => {
                          toggleSubscrptionMenu();
                        }}
                      >
                        <svg
                          className="w-5 h-5 mr-1"
                          viewBox="0 0 24 24"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path
                            fill="currentColor"
                            d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2m6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56M12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96M4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56m2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8M12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96M14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2m.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56M16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2z"
                          />
                        </svg>
                        <div className="hidden md:block">Subscribe</div>
                      </button>
                      {isSubscriptionMenuOpen && (
                        <div className="absolute bg-white shadow-md mt-[-0.7rem] rounded-md py-2 w-max px-3 z-50 right-[7rem]  shadow-lg ring-1 ring-black/5 focus:outline-none">
                          {tiolInfoList?.subMenuItems
                            ?.filter(
                              (item: SubMenuItem) =>
                                item.title === commonStrings.SUBSCRIPTION
                            )
                            .map(
                              (item: SubMenuItem, index: number) =>
                                item.title === commonStrings.SUBSCRIPTION && (
                                  <Fragment key={index}>
                                    {item.subMenuItems?.map(
                                      (subItem: SubMenuItem, index: number) => {
                                        return (
                                          <div
                                            className="border-t p-3"
                                            key={index}
                                          >
                                            <Link
                                              to={`/${subItem.webpath}`}
                                              state={{ apiUrl: subItem.apipath, apiFilterUrl: subItem.apipathfilter, catName: subItem.title }}                                             
                                              aria-disabled
                                              className="uppercase"
                                            >
                                              {subItem.title}
                                            </Link>
                                          </div>
                                        );
                                      }
                                    )}
                                  </Fragment>
                                )
                            )}
                        </div>
                      )}
                    </div>
                  </>
                )}
                <div ref={menuRef}>
                  <button
                    className="md:bg-orange-500 md:text-white text-orangered font-normal text-sm h-[3rem] mb-2 py-4 px-4 rounded-t-xl focus:outline-none flex uppercase"
                    aria-haspopup="true"
                    onClick={toggleMenu}
                  >
                    <svg
                      className="w-5 h-5 mr-1"
                      viewBox="0 0 24 24"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path
                        fill="currentColor"
                        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14"
                      />
                    </svg>
                    <div className="sm:block hidden">SEARCH</div>
                  </button>
                  {isMenuOpen && (
                    <div className="absolute bg-white shadow-md mt-[-0.4rem] rounded-md py-2 w-max px-3 z-50 right-0  shadow-lg ring-1 ring-black/5 focus:outline-none">
                      {homeSection.searches.map((search) => (
                        <div className="border-t p-3" key={search.value}>
                          <Link to={search.value} className="uppercase">
                            {search.name}
                          </Link>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <button
                  className="md:bg-orange-500 md:text-white sm:hidden text-orangered font-normal text-sm h-10 mb-2 py-4 px-4 rounded-t-md focus:outline-none flex uppercase"
                  aria-haspopup="true"
                  onClick={() => setShowMenu((prev) => !prev)}
                >
                  <LuMenuSquare size={20} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="mt-3 mx-auto">
        <MainNav />
        <MobileNav showMenu={showMenu} setShowMenu={setShowMenu} />
      </div>
    </div>
  );
};

export default MainHeader;
