import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store"; // Adjust the import according to your store file path

interface CommonState {
  isLogged: boolean;
  user: Record<string, unknown>;
  token: string | null;
  organisation_id: number | null;
  organisation_name: string;
  employee_id: number | null;
  employee_photo: string;
  logo_url: string;
  candidate_id: number | null;
  interview_id: number | null;
  position_id: number | null;
  departmentArr: unknown[];
  genderArr: unknown[];
  candidateStatus: string;
  newRoleDepartment: unknown | null;
  budjetList: unknown[];
  videList: unknown[];
  currentDynamicPaedata: Record<string, unknown>;
  getAllFooterData: unknown[];
  caseLawdata: unknown[];
  footerData: unknown[];
  mainMenuData: unknown[];
  currentClickedMenu: Record<string, unknown>;
}

const initialValue: CommonState = {
  isLogged: false,
  user: {},
  token: null,
  organisation_id: null,
  organisation_name: "",
  employee_id: null,
  employee_photo: "",
  logo_url: "",
  candidate_id: null,
  interview_id: null,
  position_id: null,
  departmentArr: [],
  genderArr: [],
  candidateStatus: "",
  newRoleDepartment: null,
  budjetList: [],
  videList: [],
  currentDynamicPaedata: {},
  getAllFooterData: [],
  caseLawdata: [],
  footerData: [],
  mainMenuData: [],
  currentClickedMenu: {},
};

const assignValueInState = (obj: Partial<CommonState>, state: CommonState) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      (state as any)[key] = obj[key as keyof CommonState];
    }
  }
};

export const commonSlice = createSlice({
  name: "common",
  initialState: initialValue,
  reducers: {
    updateState: (state, action: PayloadAction<Partial<CommonState>>) => {
      assignValueInState(action.payload, state);
    },
    resetState: (state) => {
      assignValueInState(initialValue, state);
    },
  },
});

export const { updateState, resetState } = commonSlice.actions;
export default commonSlice.reducer;

// Selector
export const selectCommon = (state: RootState) => state.common;
