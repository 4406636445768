import React, { FC, useEffect, useState, useMemo } from "react";
import { commonStrings } from "../../services/stringConstant";
import { DashboardSectionCategory } from "../../types/dashboardSectionCategory.types";
import { getApi } from "../../services/apiService";
import { apiConstant, resourceUrl } from "../../services/apiConfig";
import { Category } from "../../types/category.types";
import { useLoading } from "../../contexts/LoadingContent";
import ShimmerEffect from "../ShimmerEffect";

// Define interfaces to cover missing type definitions
interface VideoItem {
  id?: string | number;
  video_Id: string;
  title: string;
  section: string;
  path: string;
}

interface SectionData {
  id?: string | number;
  type: string;
  secBannerVideo: VideoItem[];
}

interface SecOtherdatum {
  id?: string | number;
  description: string;
}

const NewsCategory: FC = () => {
  const [categories, setCategories] = useState<DashboardSectionCategory[]>([]);
  const [commonData, setAllCategories] = useState<Category[]>([]);
  const [banners, setBanners] = useState<Category[]>([]);
  const [isLoader, setLoader] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [sectionThreeData, sectionOneData, videoItemsData] =
          await Promise.all([
            getApi(apiConstant.sectionThree),
            getApi(apiConstant.sectionOne),
            getApi(apiConstant.getVideoItems),
          ]);

        const sortedData = sectionThreeData.sort(
          (a: DashboardSectionCategory, b: DashboardSectionCategory) =>
            a.categoryOrder - b.categoryOrder
        );

        // Setting sorted data
        setCategories(sortedData);

        // const swappedCategories = swapCategories([...sectionOneData], commonStrings.TOP_NEWS, commonStrings.THE_POLICY_LAB);
        const sortedDataCategories = sectionOneData.sort(
          (a: Category, b: Category) => a.categoryOrder - b.categoryOrder
        );
        // setAllCategories(swappedCategories);
        setAllCategories(sortedDataCategories);
        setBanners(videoItemsData);
      } catch (e) {
        console.error(e);
      } finally {
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      }
    };

    fetchData();
  }, []);

  // Process video items to ensure uniqueness
  const uniqueVideos = useMemo(() => {
    if (!banners || banners.length === 0) return [];
    
    const processed = banners.map(banner => {
      // Only keep unique section data items based on ID or another unique property
      const uniqueSections = banner.sectionsdata.reduce((acc: SectionData[], section: SectionData) => {
        // Check if this section has already been added
        const sectionExists = acc.find(s => s.id === section.id);
        if (!sectionExists) {
          acc.push(section);
        }
        return acc;
      }, []);

      return {
        ...banner,
        sectionsdata: uniqueSections
      };
    });
    
    return processed;
  }, [banners]);

  if (isLoader) {
    return (
      <div className="space-y-4">
        <ShimmerEffect type="video" count={1} />
        <ShimmerEffect type="card" count={1} />
        <ShimmerEffect type="text" count={3} />
      </div>
    );
  }

  return (
    <div>
      {uniqueVideos.map((banner, index) => (
        <div className="" key={`video-top-${banner.category || index}`}>
          {banner.sectionsdata.map((section: SectionData, secIndex) => (
            <div key={`section-top-${section.id || secIndex}`} className="mb-4">
              {section.type === "Video" && (
                <div className="">
                  {section.secBannerVideo.slice(0, 1).map((item: VideoItem, itemIndex: number) => {
                    return (
                      <div key={`video-item-${item.id || itemIndex}`} className="">
                          <iframe
                            width="100%"
                            src={`https://www.youtube.com/embed/${item.video_Id}`}
                            title={item.title}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            className="rounded-md hover:shadow-xl transition-shadow duration-300"
                            style={{
                              border: "none",
                              aspectRatio: "2/1",
                              objectFit: "cover",
                            }}
                          ></iframe>
                          <div className="mt-2">
                            <p className="text-center font-bold">{item.title}</p>                            
                          </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
      {commonData.map((cat, index) => {
        if (cat.categoryName === "TOP NEWS") {
          return (
            <div key={`cat-${cat.category || index}`} className="rounded-md border mb-4 ">
              <div className="bg-gray-200 text-center rounded-t-md">
                <strong className="p-2 text-xl flex items-center text-orangered uppercase">
                  {cat.categoryName}
                </strong>
              </div>
              <div>
                {cat.sectionsdata &&
                  cat.sectionsdata.map((item, inIndex) => (
                    <div
                      key={`news-item-${item.id || inIndex}`}
                      className={
                        cat.sectionsdata.length === 1
                          ? ""
                          : item.image_Path
                          ? "grid grid-cols-2"
                          : `px-10 py-3 items-center`
                      }
                    >
                      {item.image_Path && (
                        <div className="h-28  p-2">
                          <img
                            src={item.image_Path}
                            alt={item.headlines || "Image"}
                            className="object-cover h-full w-full rounded"
                          />
                        </div>
                      )}
                      <div>
                        {cat.sectionsdata.length === 1 && (
                          <>
                            <img
                              src={cat.categoryImagePath}
                              className="w-full"
                              alt=""
                            />
                          </>
                        )}
                        <div
                          className={`${
                            cat.sectionsdata.length === 1 ? "p-5" : ""
                          }`}
                        >
                          <div className="flex items-center gap-4">
                            {item.notificationNo === "0" && (
                              <span className="text-orangered">
                                {item.date}
                              </span>
                            )}
                            <span>
                              <span className="">
                                {cat.sectionsdata.length === 1 ? `|  By ` : ""}
                              </span>{" "}
                              <span className="font-bold">
                                {cat.sectionsdata.length === 1
                                  ? `   ${cat.sectionsdata[0].author} `
                                  : ""}
                              </span>{" "}
                            </span>
                          </div>
                          <div
                            onClick={() => {
                              let data = item.webpathcontent.split("/");
                              let id = data[data.length - 1];
                              window.open(`/news/details?id=${id}&type=news`, "_blank");
                            }}
                            className=" flex justify-between gap-4 hover:underline hover:text-orangered"
                          >
                            <div>
                              {item.headlines.length > 100
                                ? `${item.headlines.slice(0, 100)}...`
                                : item.headlines}
                            </div>
                            {item.notificationNo !== "0" && (
                              <div className="text-orangered float-end">
                                {item.notificationNo}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          );
        }
        return null;
      })}
      {categories
        .sort(
          (a: DashboardSectionCategory, b: DashboardSectionCategory) =>
            a.categoryOrder - b.categoryOrder
        )
        .map((cat, index) => {
          if (cat.category === 14) {
            return (
              <div key={`category-${cat.category || index}`} className="rounded-md border mb-3">
                <div className="bg-gray-200 h-12 rounded-t-md">
                  <strong className="p-2 text-xl flex items-center text-orangered uppercase">
                    {cat.categoryName}
                  </strong>
                </div>
                <div className="overflow-hidden">
                  {cat.secOtherdata &&
                    cat.secOtherdata.map((item: SecOtherdatum, inIndex) => (
                      <div
                        key={`other-data-${item.id || inIndex}`}
                        className={`custom-html-content ${
                          cat.categoryName === commonStrings.VISION_360
                            ? "text-center"
                            : "px-10 py-3"
                        }`}
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    ))}
                </div>
              </div>
            );
          }
          return null;
        })}
      {/* Using uniqueVideos for bottom banners too */}
      {uniqueVideos.map((banner, index) => (
        <div className="" key={`video-bottom-${banner.category || index}`}>
          {banner.sectionsdata.map((section: SectionData, secIndex) => (
            <div key={`section-bottom-${section.id || secIndex}`} className="mb-4">
              <div className="">
                {/* Filter to get the first item that matches the condition */}
                {section.secBannerVideo
                  .filter((item: VideoItem) => item.section === "Banner Right")
                  .slice(0, 1)
                  .map((item: VideoItem, itemIndex: number) => (
                    <div key={`banner-${item.id || itemIndex}`} className="rounded">
                      <div>
                        <img
                          src={item.path}
                          alt=""
                          className=" w-full  rounded-md"
                          style={{
                            aspectRatio: "1/1",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default NewsCategory;
