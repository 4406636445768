// export const apiUrl = "https://bp.lawclerk.legal";
// export const apiUrl = "";

export const resourceUrl = "https://admin.taxindiaonline.com";
export const apiUrl = "https://api.taxindiaonline.com";
export const userApiUrl = "https://umsapi.taxindiaonline.com";
// export const resourceUrl = "http://34.229.120.75:8080";
// export const apiUrl = "http://34.229.120.75:8081";
// export const userApiUrl = "http://34.229.120.75:8091";
// export const apiUrl = "http://34.299.120.75:8081";
// const apiUrlPrefix = `${apiUrl}/api`;
export const baseUrl = `${apiUrl}/api`;
export const userBaseUrl = `${userApiUrl}/api`;

export const apiConstant = {
  domainLogin: baseUrl + '/login/ValidateUserDomainWise/',
  getMenuItems: baseUrl + "/DynamicMenuItem/GetDynamicMenuItem/1",
  getFooterList: baseUrl + "/FooterMenu/GetDynamicFooterItem",
  getTiolWebSiteIcons:
    baseUrl + "/PdfBlob/GetPdfBlobIndexPagewithoutToken/20/101/0/0/null",
  sectionOne: baseUrl + "/HomePageSectionsData/GetHomePageSectionsData",
  sectionTwo: baseUrl + "/HomePageSectionsData/GetHomePageSectionsData",
  getVideoItems: baseUrl + "/HomePageBannerVideo/GetHomePageBannerVideo",
  sectionThree:
    baseUrl + "/HomePageOtherSectionData/GetHomePageOtherSectionsData",

  getIsLikeBookmark: baseUrl + "/Utility/GetIsLIkeBookMark", //auth
  getLikeDetails: baseUrl + "/CaselawIndexPage/GetCaselawById", //auth
  getIsLike: baseUrl + "/Utility/GetIsLIke/1",
  likeDetails: baseUrl + "/Utility/PostLike", //auth
  dislikeDetails: baseUrl + "/Utility/PostDisLike", //auth

  getAllBookmarked: baseUrl + "/Utility/GetBooKMark", //auth
  postBookmarked: baseUrl + "/Utility/PostBookMark", //auth
  deleteBookmarked: baseUrl + "/Utility/DeleteBooKMark", //auth

  getAllCommentsWithReplies: baseUrl + `/Utility/GetFinalComments`,
  getAllComments: baseUrl + "/Utility/GetComments", //auth
  postComments: baseUrl + "/Utility/PostComment", //auth
  postCommentsReplyOneLevel: baseUrl + "/Utility/PostCommentReply", //auth
  postCommentsReplySecondLevel: baseUrl + "/Utility/PostCommentReReply", //auth

  getCommentsReplyOneLevel: baseUrl + "/Utility/GetCommentReply", //auth
  getCommentsReReplySecondLevel: baseUrl + "/Utility/GetCommentReReply", //auth

  updateComment: baseUrl + "/Utility/UpdateComment", //auth
  deleteComment: baseUrl + "/Utility/DeleteComment", //auth
  updateCommentReply: baseUrl + "/Utility/UpdateCommentReply", //auth
  updateCommentReReply: baseUrl + "/Utility/UpdateCommentReReply", //auth
  deleteCommentReply: baseUrl + "/Utility/DeleteCommentReply", //auth
  deleteCommentReeReply: baseUrl + "/Utility/DeleteCommentReReply", //auth

  ///////
  getNewsCategory: baseUrl + "/Utility/GetNewsCategory",
  getNewsIndexPagesAdvSearch:
    baseUrl + "/AdvSearchNews/GetNewsIndexPagesAdvSearch", //auth
  getNewsTypeAuthor: baseUrl + "/Utility/GetNewsTypeAuthor",
  getNewsById: baseUrl + "/NewsIndexPage/GetNewsById",
  getNewsSearchById: baseUrl + "/NewsIndexPage/GetNewsSearchById",

  getCaselawSearchById: baseUrl + "/CaselawIndexPage/GetCaselawSearchById",

  getLaws: baseUrl + "/Utility/GetLaw",
  getCourt: baseUrl + "/Utility/GetCourt",
  getAssesmentYear: baseUrl + "/Utility/GetAssesmentYear",
  getInfavour: baseUrl + "/Utility/GetInfavour",
  getbenchCity: baseUrl + "/Utility/GetBench",
  getbenchType: baseUrl + "/Utility/GetBenchType",
  getCitationYear: baseUrl + "/Utility/GetCitationYear",
  getCaselawAdvSearch: baseUrl + "/Utility/AutofillPartyName",
  getAdvSearchCaselaw: baseUrl + "/AdvSearchCaselaw/GetCaselawAdvSearch1",

  getNotificationCategory: baseUrl + "/Utility/GetNotificationCategory", //auth
  getNotificationAdvSearch:
    baseUrl + "/AdvSearchNotification/GetNotificationAdvSearch", //auth
  getNotificationSubCategory: baseUrl + "/Utility/GetNotificationSubCategory",
  getNotificationLevel1: baseUrl + "/Utility/GetNotificationLevel1", //auth
  getNotificationLevel2: baseUrl + "/Utility/GetNotificationLevel2", //auth
  getNotificationById: baseUrl + "/NotificationIndexPage/GetNotificationById", //auth
  GetNotificationAdvSearch: baseUrl + "/NotificationIndexPage/GetNotificationAdvSearch", //auth
  getNotificationSearchById:
    baseUrl + "/NotificationIndexPage/GetNotificationSearchById", //auth
  getYear: baseUrl + "/Utility/GetNotificationYear", //auth
  getPressReleaseById: baseUrl + "/PressRelease/GetPressReleaseById", //auth

  getPdfBlobById: baseUrl + "/PdfBlob/GetPdfBlobById/", //auth

  getSGSTState: baseUrl + "/SGSTIndexPage/GetSGSTState", //auth
  getSGSTUT: baseUrl + "/SGSTIndexPage/GetSGSTUT", //auth
  getSGSTIndexPage:
    baseUrl + "/SGSTIndexPage/GetSGSTIndexPage/GST Act (multiple years)", //auth

  getCaseLawById: baseUrl + "/CaselawIndexPage/GetCaselawById", //auth
  GetCaselawSearchById: baseUrl + "/CaselawIndexPage/GetCaselawSearchById", //auth

  userChangePassword: baseUrl + "/UserManagement/ChangePassword", //auth
  userChangePasswordExpire: baseUrl + "/UserManagement/ChangePasswordExpire",
  userForgotPassword: baseUrl + "/UserManagement/ForgetPassword",
  userChangePasswordPolicy: baseUrl + "/UserManagement/PasswordPolicy",

  // userLogin: userBaseUrl + "/Login?userName",
  userLogin: baseUrl + "/login/ValidateUser",

  boolUserName: baseUrl + "/Utility/BoolUsername",

  // userRegistration: userBaseUrl + "/RegisterOnline/PostTblRegistrationOnline",
  userRegistration: baseUrl + "/UserManagement/RegisterOnline",

  urlRedirection: baseUrl + "/UrlRedirection/GetNewUrl?Url=",

  /////Advance Search
  autoFillPartyName: baseUrl + "/Utility/AutofillPartyName",
};

// api/HomePageBannerVideo/GetHomePageBannerVideo

// "proxy": "http://3.89.105.249",
