import React, { Fragment, useEffect, useState } from 'react';
import { FaApple, FaFacebook, FaGoogle, FaInstagram, FaLinkedin, FaTwitter, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { BsAndroid2 } from "react-icons/bs";
import { getApi } from '../services/apiService';
import { apiConstant } from '../services/apiConfig';
import { MenuType, SubMenuItem } from '../types/menuType.types';
import { Link } from 'react-router-dom';
import { commonStrings } from '../services/stringConstant';
import { WebSite } from '../types/website.types';
import AdComponent from '../components/googleAds';

const iconMap: { [key: string]: React.ElementType } = {
    IOS: FaApple,
    Android: BsAndroid2,
    Facebook: FaFacebook,
    Twitter: FaTwitter,
    Linkdin: FaLinkedin,
    Instagram: FaInstagram,
    WhatsApp: FaWhatsapp,
    Youtube: FaYoutube,
    Google: FaGoogle,
};

const MainFooter: React.FC = () => {
    const [footerList, setFooterList] = useState<MenuType[]>([]);
    const [footerImgList, setFooterImgList] = useState<WebSite[]>([]);

    useEffect(() => {
        getApi(apiConstant.getFooterList)
            .then((res) => {
                setFooterList(res);
            })
            .catch((e) => {
                console.error(e);
            });
        getApi(apiConstant.getTiolWebSiteIcons)
            .then((res) => {
                setFooterImgList(res);
            })
            .catch((e) => {
                console.error(e);
            });
    }, []);

    const renderMenuItems = (items: MenuType[] | SubMenuItem[], depthLevel: number = 0) => {
        return (
            <div className='flex  align-center justify-around gap-4'>
                {items.filter(item => item.apipath).map((item, index) => (
                    <div key={`${depthLevel}-${index}`}>
                        <Link to={`/${item.webpath}`} state={{ apiUrl: item.apipath, apiFilterUrl: item.apipathfilter, catName: item.title }} className='flex items-center text-center mb-1 hover:underline m-auto' ><div className="w-2 h-2 mr-2 bg-white rounded-full"></div> <div className=''>{item.title}</div> </Link>
                        {item.subMenuItems && item.subMenuItems.length > 0 && (
                            <div className="pl-4">
                                {renderMenuItems(item.subMenuItems, depthLevel + 1)}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        );
    };
    return (
        <>
            {/* <AdComponent /> */}
            <div className='text-white  rounded-md bg-orange-main  container mx-auto py-8'>
                <div className="grid md:grid-cols-2 gap-4  pl-8">
                    <div className="flex items-center">
                        {footerList.map((item, index) => (
                            (item.title === commonStrings.TIOL_APPS) && (
                                <Fragment key={index}>
                                    <div className="uppercase">{item.title}</div>
                                    {item.subMenuItems.map((subItem, index) => {
                                        const IconComponent = iconMap[subItem.title.toString()];
                                        return (
                                            <div className='bg-white p-1 mx-2 rounded-full' key={index}>
                                                <Link to={subItem.apipath} target='_blank'>
                                                    <IconComponent size={20} color='#F36E29' />
                                                </Link>
                                            </div>
                                        );
                                    })}

                                </Fragment>
                            )
                        ))}
                    </div>
                    <div className="flex justify-end items-center">
                        {footerList.map((item, index) => (
                            (item.title === commonStrings.FOLLOW_US) && (
                                <Fragment key={index}>
                                    <div className="uppercase">{item.title} :</div>
                                    {item.subMenuItems.map((subItem, index) => {
                                    const IconComponent = iconMap[subItem.title.toString()];
                                        return (
                                            <div className='bg-white p-1 mx-2 rounded-full' key={index}>
                                                <Link to={subItem.apipath} target='_blank'>
                                                    <IconComponent size={20} color='#F36E29' />
                                                </Link>
                                            </div>
                                        );
                                    })}

                                </Fragment>
                            )
                        ))}
                    </div>
                </div>
                <div className=" pt-8 pl-8">
                    {footerList.map((item, index) => (
                        (item.title === commonStrings.TIOL_INFO) && (
                            <div key={index}>
                                {/* <div>{item.title}</div> */}
                                {item.subMenuItems && item.subMenuItems.length > 0 && (
                                    <div className="mx-auto md:pl-12">
                                        {renderMenuItems(item.subMenuItems)}
                                    </div>
                                )}
                            </div>
                        )
                    ))}
                </div>
            </div>
            <div className='mt-4 container mx-auto'>
                {footerImgList.map((item, index) => (
                    <div key={index} dangerouslySetInnerHTML={{ __html: item.description }} className='text-center  py-4'></div>
                ))}

            </div>
            <div className='copy-right section uppercase text-center py-5 hidden'>
                <span className='font-bold'>
                    <span>{commonStrings.TIOL_GROUP}.</span> <span>{commonStrings.COPYRIGHT}</span>  &copy;<span className='pl-2'>{new Date().getFullYear()}</span>
                </span>
                <span className='font-bold px-3'>
                    |
                </span>
                <span>
                    {commonStrings.PRIVACYPOLICY}
                </span>
                <span className='font-bold px-3'>
                    |
                </span>
                <span className='font-bold uppercase'>{commonStrings.TIOLCOMPANY}</span>
            </div>
        </>
    );
};

export default MainFooter;
