import React from 'react';
import { commonStrings } from '../services/stringConstant';
import { toast } from 'react-toastify';

const SubscriptionToast = (): JSX.Element => {
    const subscriptionUrl = "https://api.taxindiaonline.com/api/PdfBlob/GetPdfBlobIndexPagewithoutToken/16/3080/0/0/null"; 
  return (
    <div className="flex flex-col w-full">
      {/* <h3 className="text-zinc-800 text-sm font-semibold flex items-center gap-1">
        <span className="text-grey-700">⚠️</span> {commonStrings.SUBSCRIPTION}
      </h3> */}

      <div className="pl-5 mt-2 font-semibold">
        <p className="text-sm">{commonStrings.SUBSCRIPTION_REQUIRED}</p>

        <div className="flex items-center gap-2 mt-3">
          <button
            onClick={() => {
              window.location.href = `/tiolinfo/subscriptions?apiSubscriptions=subscriptions`;              
              toast.dismiss();
            }}
            className="transition-all border-none text-sm font-semibold bg-transparent border rounded-md px-4 py-2 text-indigo-600 active:scale-[.95] hover:bg-indigo-50"
          >
            {commonStrings.SUBSCRIPTIONS}
          </button>
          <button
            onClick={() => {
              toast.dismiss();
              window.location.href = "/";
            }}
            className="transition-all border-none text-sm bg-transparent border font-semibold rounded-md px-4 py-2 text-grey-400 active:scale-[.95] hover:bg-gray-50"
          >
            {commonStrings.DISMISS}
          </button>
        </div>
      </div>
    </div>
  );
};

export const showSubscriptionToast = () => {
  toast(<SubscriptionToast />, {
    autoClose: false,
    closeButton: true,
    closeOnClick: false,
    draggable: true,
    icon: false,
    position: "top-center",
    className: "subscription-toast"
  });
};

export default SubscriptionToast;