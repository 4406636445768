import axios from "axios";
import { toast } from "react-toastify";
import { commonStrings } from "./stringConstant";
import SubscriptionToast from "../components/SubscriptionToast";
import React from 'react';

// Define custom types for API response and error
interface ApiResponse<T> {
  status: number;
  message: string;
  data: T;
  token?: string;
}

interface ApiError {
  message: string;
  status?: number;
  data?: any;
}

interface CacheItem {
  data: any;
  timestamp: number;
}

// Cache object to store API responses
const apiCache: Record<string, CacheItem> = {};

// Cache duration in milliseconds (30 minutes)
const CACHE_DURATION = 30 * 60 * 1000;

// Function to check if cache is still valid
const isCacheValid = (cacheKey: string): boolean => {
  const cacheItem = apiCache[cacheKey];
  if (!cacheItem) return false;
  
  const now = new Date().getTime();
  return now - cacheItem.timestamp < CACHE_DURATION;
};

// Create an Axios instance with base URL
const api = axios.create({
  baseURL: "",
});

// Function to get the current timestamp
const getCurrentTimestamp = (): number => new Date().getTime();

// Function to get the last activity timestamp from localStorage
const getLastActivityTimestamp = (): number | null => {
  const timestamp = localStorage.getItem("lastActivityTimestamp");
  return timestamp ? parseInt(timestamp, 10) : null;
};

// Function to set the last activity timestamp in localStorage
const setLastActivityTimestamp = (): void => {
  localStorage.setItem(
    "lastActivityTimestamp",
    getCurrentTimestamp().toString()
  );
};

// Function to check if the user is inactive
const checkInactivity = (): boolean => {
  const lastActivity = getLastActivityTimestamp();
  if (!lastActivity) return false; // No timestamp, so not inactive

  const now = getCurrentTimestamp();
  const elapsedMinutes = (now - lastActivity) / (1000 * 60);
  return elapsedMinutes > 15;
};

// Handle session expiration
const handleInactivity = (): void => {
  if (checkInactivity()) {
    // Clear local storage and redirect to sign-in page
    if (typeof window !== "undefined") {
      localStorage.clear();
      toast.error("Session expired. Please log in again.");
      setTimeout(() => {
        window.location.href = "/sign-in";
      }, 3500);
    }
  }
};

// Set an interval to check inactivity every minute
setInterval(handleInactivity, 6000);

// Define a custom error handler
const errorHandler = (error: any): Promise<ApiError> => {
  if (error.response) {
    // The request was made and the server responded with a status code
    let status = error.response.status;
    let message = error.response.data?.message;
    if (status === 403) {
      // Clear local storage
      if (typeof window !== "undefined") {
        localStorage.clear();
      }
      setTimeout(() => {
        window.location.href = "/sign-in";
      }, 3500);

      return Promise.reject({
        message: message,
        status: status,
        data: [],
      });
    }
    if (status === 401) {
      toast.warn(SubscriptionToast , {
        autoClose: false,
        closeButton: true,
        closeOnClick: false,
        draggable: true,
        icon: false,
        position: "top-right",
        className: "subscription-toast",        
      });
      return Promise.reject({
        message: message,
        status: status,
        data: [],
      });
    }
    return Promise.reject({
      message: message,
      status: status,
      data: null,
    });
  } else if (error.request) {
    // The request was made but no response was received
    return Promise.reject({ message: "No response received from server" });
  } else {
    // Something happened in setting up the request that triggered an Error
    return Promise.reject({
      message: `Request setup error: ${JSON.stringify(error)}`,
    });
  }
};

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use((response) => {
  setLastActivityTimestamp(); // Update last activity time
  return response; // Return only the data
}, errorHandler);

// Define common API functions
export const getApi = async (resource: string): Promise<any> => {
  try {
    // Check if valid cached response exists
    if (isCacheValid(resource)) {
      return apiCache[resource].data;
    }
    
    // If no cache or cache expired, make the API call
    const response = await api.get(`${resource}`);
    
    // Cache the response
    apiCache[resource] = {
      data: response.data,
      timestamp: getCurrentTimestamp()
    };
    
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Clear specific cache entry
export const clearCache = (resource: string): void => {
  if (apiCache[resource]) {
    delete apiCache[resource];
  }
};

// Clear entire cache
export const clearAllCache = (): void => {
  Object.keys(apiCache).forEach(key => {
    delete apiCache[key];
  });
};

export const post = async (
  resource: string,
  data: any,
  headers?: any
): Promise<any> => {
  try {
    const response = await api.post(`${resource}`, data, {
      headers: headers,
    });
    
    // Invalidate cache for this resource
    clearCache(resource);

    // Return both data and headers from the response
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const update = async (
  resource: string,
  id: string,
  data: any,
  headers?: any
): Promise<any> => {
  try {
    const response = await api.put(`${resource}${id ? `/${id}` : ""}`, data, {
      headers: headers,
    });
    
    // Invalidate cache for this resource
    clearCache(resource);
    
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const remove = async (
  resource: string,
  id?: string,
  data?: any
): Promise<any> => {
  try {
    // Using axios directly as a workaround for delete with body
    const url = `${resource}${id ? `/${id}` : ""}`;
    const response = await axios({
      method: 'delete',
      url: url,
      data: data
    });
    
    // Invalidate cache for this resource
    clearCache(resource);
    
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Export the API instance
export default api;
