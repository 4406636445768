import React, { useEffect, useRef, useState, useCallback } from "react";
import { getApi } from "../services/apiService";
import { apiConstant } from "../services/apiConfig";
import { MenuType, SubMenuItem } from "../types/menuType.types";
import { Link } from "react-router-dom";

interface MobileMenuProps {
    showMenu: boolean;
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

// Common type for MenuType and SubMenuItem to use in reduce functions
type MenuItemType = MenuType | SubMenuItem;

const MobileNav: React.FC<MobileMenuProps> = ({ showMenu, setShowMenu }) => {

    const ref = useRef<HTMLUListElement>(null); // Ref for ul element

    const [menuItems, setMenus] = useState<MenuType[]>([]);
    useEffect(() => {
        getApi(apiConstant.getMenuItems)
            .then((res) => {
                // Ensure no duplicate menu items by ID
                const uniqueMenus = res.reduce((acc: MenuType[], menu: MenuType) => {
                    if (!acc.find(m => m.id === menu.id)) {
                        acc.push(menu);
                    }
                    return acc;
                }, [] as MenuType[]);
                setMenus(uniqueMenus);
            })
            .catch((e) => {
                console.error(e);
            });
    }, []);

    useEffect(() => {
        const handler = (event: MouseEvent | TouchEvent) => {
            if (showMenu && ref.current && !ref.current.contains(event.target as Node)) {
                setShowMenu(false);
            }
        };

        // Only add event listeners if menu is showing
        if (showMenu) {
            document.addEventListener("mousedown", handler);
            document.addEventListener("touchstart", handler);
        }

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [showMenu, setShowMenu]);

    return (
        <nav className="mobile-nav">
            {showMenu && (
                <ul className="menus mt-24 border" ref={ref}>
                    {menuItems.map((menu, index) => {
                        return (
                            <MobileMenuItems
                                items={menu}
                                key={`mobile-menu-${menu.id || index}`}
                                depthLevel={menu.subMenuItems.length}
                                showMenu={showMenu}
                                setShowMenu={setShowMenu}
                                cat={menu.title}
                            />
                        );
                    })}
                </ul>
            )}
        </nav>
    );
};


interface MobileMenuItemsProps {
    items: MenuType | SubMenuItem;
    depthLevel: number;
    showMenu: boolean;
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
    cat: string;
}

const MobileMenuItems: React.FC<MobileMenuItemsProps> = ({
    items,
    depthLevel,
    showMenu,
    setShowMenu,
    cat
}) => {
    const [dropdown, setDropdown] = useState<boolean>(false);

    const closeDropdown = useCallback(() => {
        dropdown && setDropdown(false);
        showMenu && setShowMenu(false);
    }, [dropdown, showMenu, setShowMenu]);

    const toggleDropdown = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        setDropdown((prev) => !prev);
    }, []);

    return (
        <li className="menu-items" onClick={closeDropdown}>
            {items.subMenuItems && items.subMenuItems.length > 0 ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? "true" : "false"}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {items.webpath ?
                            <Link to={`/${items.webpath}`} state={{ apiUrl: items.apipath, apiFilterUrl: items.apipathfilter, catName: `${cat} > ${items.title}` }}  >
                                {items.title}
                            </Link> : <div onClick={(e) => toggleDropdown(e)}>
                                {items.title}
                            </div>
                        }
                        {depthLevel > 0 && <div onClick={(e) => toggleDropdown(e)}>
                            {dropdown ? (
                                <span className="arrow-close" />
                            ) : (
                                <span className="arrow" />
                            )}
                        </div>}
                    </button>
                    <MobileDropdown
                        depthLevel={items.subMenuItems.length}
                        cat={cat}
                        submenus={items.subMenuItems}
                        dropdown={dropdown}
                        showMenu={showMenu}
                        setShowMenu={setShowMenu}
                    />
                </>
            ) : (
                <Link to={`/${items.webpath}`} state={{ apiUrl: items.apipath, apiFilterUrl: items.apipathfilter, catName: items.title }} >{items.title}</Link>
            )}
        </li>
    );
};

interface MobileDropdownProps {
    submenus: SubMenuItem[] | MenuType[];
    dropdown: boolean;
    depthLevel: number;
    showMenu: boolean;
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
    cat: string;
}

const MobileDropdown: React.FC<MobileDropdownProps> = ({
    submenus,
    dropdown,
    depthLevel,
    showMenu,
    setShowMenu,
    cat
}) => {
    const newDepthLevel = depthLevel + 1;
    const dropdownClass = newDepthLevel > 1 ? "dropdown-submenu" : "";

    // Ensure items are unique by ID
    const uniqueSubmenus = React.useMemo(() => {
        // Cast submenus to a common type to avoid type errors with reduce
        const typedSubmenus = submenus as MenuItemType[];
        
        return typedSubmenus.reduce((acc: MenuItemType[], submenu: MenuItemType) => {
            if (!acc.find(item => item.id === submenu.id)) {
                acc.push(submenu);
            }
            return acc;
        }, [] as MenuItemType[]);
    }, [submenus]);

    return (
        <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
            {uniqueSubmenus.map((submenu: MenuItemType, index: number) => (
                <MobileMenuItems
                    items={submenu}
                    key={`mobile-submenu-${submenu.id || index}`}
                    depthLevel={newDepthLevel}
                    showMenu={showMenu}
                    setShowMenu={setShowMenu}
                    cat={cat}
                />
            ))}
        </ul>
    );
};

export default MobileNav;