import React, { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";

const BackToTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled up to given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top scroll coordinate to 0
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <button
          onClick={scrollToTop}
          className={`fixed bottom-8 right-8 bg-orange-main hover:bg-orange-600 text-white p-4 rounded-full shadow-xl 
                    transition-all duration-500 ease-in-out transform hover:scale-110 z-50                    
                      hover:ring-orange-300
                    flex items-center justify-center`}
          aria-label="Back to top"
        >
          <FaArrowUp
            size={24}
            className="transition-transform duration-300 group-hover:translate-y-[-2px]"
          />
        </button>
      )}
    </>
  );
};

export default BackToTop;
