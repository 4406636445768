import React, { useEffect, useState, useCallback } from "react";
import { getApi } from "../services/apiService";
import { apiConstant } from "../services/apiConfig";
import { MenuType, SubMenuItem } from "../types/menuType.types";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { VscBlank } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";

const MainNav: React.FC = () => {
    const navigate = useNavigate();
    const [menuItems, setMenus] = useState<MenuType[]>([]);
    const [categoryName, setCategoryName] = useState<String>('');
    const [selectedSubMenus, setSelectedMenus] = useState<SubMenuItem[]>([]);
    const [selectedSubSubMenus, setSelectedSubMenus] = useState<SubMenuItem[]>([]);
    const [selectedSubSubSubMenus, setSelectedSubSubMenus] = useState<SubMenuItem[]>([]);
    const [selectedSubSubSubSubMenus, setSelectedSubSubSubMenus] = useState<SubMenuItem[]>([]);

    useEffect(() => {
        getApi(apiConstant.getMenuItems)
            .then((res: MenuType[]) => {
                // Ensure no duplicate menu items by ID if needed
                const uniqueMenus = res.reduce((acc: MenuType[], menu: MenuType) => {
                    if (!acc.find(m => m.id === menu.id)) {
                        acc.push(menu);
                    }
                    return acc;
                }, []);
                setMenus(uniqueMenus);
            })
            .catch((e) => {
                console.error(e);
            });
    }, []);

    const handlerSubMenus = useCallback((subMenuItems: SubMenuItem[]) => {
        setSelectedMenus(subMenuItems);
        setSelectedSubMenus([]);
        setSelectedSubSubMenus([]);
        setSelectedSubSubSubMenus([]);
    }, []);

    const handlerSubSubMenus = useCallback((subMenuItems: SubMenuItem[]) => {
        setSelectedSubMenus(subMenuItems);
        setSelectedSubSubMenus([]);
        setSelectedSubSubSubMenus([]);
    }, []);

    const handlerSubSubSubMenus = useCallback((subMenuItems: SubMenuItem[]) => {
        setSelectedSubSubMenus(subMenuItems);
    }, []);

    const handlerSubSubSubSubMenus = useCallback((subMenuItems: SubMenuItem[]) => {
        setSelectedSubSubSubMenus(subMenuItems);
    }, []);

    const UlRef = React.useRef<HTMLUListElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent | TouchEvent) => {
            if (selectedSubMenus && UlRef.current && !UlRef.current.contains(event.target as Node)) {
                setSelectedMenus([]);
                setSelectedSubMenus([]);
                setSelectedSubSubMenus([]);
                setSelectedSubSubSubMenus([]);
                setCategoryName('');
            }
        };

        // Add event listeners
        document.addEventListener("mousemove", handleClickOutside);
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);

        // Clean up event listeners
        return () => {
            document.removeEventListener("mousemove", handleClickOutside);
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, [selectedSubMenus]);
    
    return (
        <nav className="desktop-nav container mx-auto  bg-white mb-2 py-2 text-left z-[9999]" ref={UlRef} >
            <ul className="container  flex justify-end" >
                {menuItems.map((menu, index) => (
                    <li
                        key={`main-menu-${menu.id || index}`}
                        className={`mx-2 cursor-pointer  flex justify-center items-center hover:border-b  hover:text-orangered ${menu.apipath !== '' && ' text-orangered '} ${selectedSubMenus === menu.subMenuItems ? ' text-orangered border-b  border-b-orangered ' : ''}`}
                        onMouseEnter={() => {
                            handlerSubMenus(menu.subMenuItems);
                            setCategoryName(menu.title);
                        }}
                        onClick={() => {
                            if (menu.apipath !== '') {
                                window.open(menu.apipath, '_blank');
                            }
                        }}
                    >
                        {menu.title}
                        {/* {menu.subMenuItems.length > 0 && (selectedSubMenus === menu.subMenuItems ? <FaAngleDown className="ml-2" /> : <FaAngleRight className="ml-2" />)} */}
                    </li>
                ))}
            </ul>
            {selectedSubMenus.length > 0 && (
                <div className=" pb-10 p-5 grid grid-cols-12 w-full border  bg-white shadow-2xl absolute z-50 mt-2 left-0 pl-48">

                    <ul className="border-r-2 col-span-2 ">
                        {selectedSubMenus.map((item, index) => (
                            <li 
                                key={`sub-menu-${item.id || index}`} 
                                className={`py-1 mx-2 px-5 cursor-pointer flex justify-end items-center hover:underline hover:text-orangered ${selectedSubSubMenus === item.subMenuItems && ' text-orangered '}`} 
                                onMouseEnter={() => handlerSubSubMenus(item.subMenuItems || [])} 
                                onClick={() => {
                                    item.subMenuItems && item.subMenuItems.length > 0 ? handlerSubSubMenus(item.subMenuItems) : navigate(`/${item.webpath}`, {
                                        state: {
                                            apiUrl: item.apipath,
                                            apiFilterUrl: item.apipathfilter,
                                            catName: `${categoryName} > ${item.title}`
                                        }
                                    });
                                }}
                            >
                                <div className="flex items-center">{item.title}</div> {item.subMenuItems && item.subMenuItems.length > 0 ? (selectedSubSubMenus === item.subMenuItems ? <FaAngleRight className=" ml-2" /> : <FaAngleRight className="ml-2" />) : <VscBlank className="ml-2" />}
                            </li>
                        ))}
                    </ul>
                    {selectedSubSubMenus.length > 0 && 
                        <ul className={`px-2  col-span-3 text-left ${selectedSubSubMenus.length > 0 && selectedSubSubMenus.find((item) => item.subMenuItems && item.subMenuItems.length > 0) ? ' border-r-2 border-[#dbdde1] ' : ''}`}>
                            {selectedSubSubMenus.map((subItem, index) => (
                                <li 
                                    key={`sub-sub-menu-${subItem.id || index}`}
                                    className={`py-1 mx-2 cursor-pointer flex justify-between items-center hover:underline hover:text-orangered ${selectedSubSubSubMenus === subItem.subMenuItems && 'text-orangered'}`} 
                                    onMouseEnter={() => handlerSubSubSubMenus(subItem?.subMenuItems || [])} 
                                    onClick={() => {
                                        subItem.subMenuItems && subItem.subMenuItems.length > 0 ? handlerSubSubSubMenus(subItem.subMenuItems) : navigate(`/${subItem.webpath}`, {
                                            state: {
                                                apiUrl: subItem.apipath,
                                                apiFilterUrl: subItem.apipathfilter,
                                                catName: `${categoryName} > ${subItem.title}`
                                            }
                                        });
                                    }}
                                >
                                    <div className="flex items-center "><div className="w-2 h-2 mr-2  bg-black rounded-full"></div> {subItem.title}</div>    {subItem.subMenuItems && subItem.subMenuItems.length > 0 && (selectedSubSubSubMenus === subItem.subMenuItems ? <FaAngleRight className=" ml-2" /> : <FaAngleRight className="ml-2" />)}
                                </li>
                            ))}
                        </ul>
                    }
                    {selectedSubSubSubMenus.length > 0 && 
                        <ul className={`px-2  col-span-3 text-left ${selectedSubSubSubMenus.length > 0 && selectedSubSubSubMenus.find((item) => item.subMenuItems && item.subMenuItems.length > 0) ? ' border-r-2 border-[#dbdde1] ' : ''}`}>
                            {selectedSubSubSubMenus.map((item, index) => (
                                <li 
                                    key={`sub-sub-sub-menu-${item.id || index}`}
                                    className={`py-1 mx-2 cursor-pointer flex justify-between items-center hover:underline hover:text-orangered ${selectedSubSubSubSubMenus === item.subMenuItems && 'text-orangered'}`}
                                    onMouseEnter={() => handlerSubSubSubSubMenus(item.subMenuItems || [])}
                                    onClick={() => {
                                        item.subMenuItems && item.subMenuItems.length > 0 ? handlerSubSubSubSubMenus(item.subMenuItems) : navigate(`/${item.webpath}`, {
                                            state: {
                                                apiUrl: item.apipath,
                                                apiFilterUrl: item.apipathfilter,
                                                catName: `${categoryName} > ${item.title}`
                                            }
                                        });
                                    }}
                                >
                                    <div className="flex items-center "><div className="w-2 h-2 mr-2  bg-black rounded-full"></div> {item.title}</div>
                                    {item.subMenuItems && item.subMenuItems.length > 0 && (selectedSubSubSubSubMenus === item.subMenuItems ? <FaAngleRight className="ml-2" /> : <FaAngleRight className="ml-2" />)}
                                </li>
                            ))}
                        </ul>
                    }
                    {selectedSubSubSubSubMenus.length > 0 && 
                        <ul className={`px-2  col-span-3 text-left ${selectedSubSubSubSubMenus.length > 0 && selectedSubSubSubSubMenus.find((item) => item.subMenuItems && item.subMenuItems.length > 0) ? ' border-r-2 border-[#dbdde1] ' : ''}`}>
                            {selectedSubSubSubSubMenus.map((item, index) => (
                                <li 
                                    key={`sub-sub-sub-sub-menu-${item.id || index}`}
                                    className={`py-1 mx-2 cursor-pointer flex justify-between items-center hover:underline hover:text-orangered ${selectedSubMenus === item.subMenuItems && 'text-orangered'} `}
                                    onMouseEnter={() => handlerSubSubSubMenus(item.subMenuItems || [])}
                                    onClick={() => {
                                        item.subMenuItems && item.subMenuItems.length > 0 ? handlerSubSubSubMenus(item.subMenuItems) : navigate(`/${item.webpath}`, {
                                            state: {
                                                apiUrl: item.apipath,
                                                apiFilterUrl: item.apipathfilter,
                                                catName: `${categoryName} > ${item.title}`
                                            }
                                        });
                                    }}
                                >
                                    <div className="flex items-center "><div className="w-2 h-2 mr-2  bg-black rounded-full"></div> {item.title}</div>
                                    {item.subMenuItems && item.subMenuItems.length > 0 && <FaAngleRight className="ml-2" />}
                                </li>
                            ))}
                        </ul>
                    }
                </div>
            )}
        </nav>
    );
};


export default MainNav;
