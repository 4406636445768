import React from 'react';

interface ShimmerProps {
  type?: 'banner' | 'card' | 'video' | 'text';
  count?: number;
  height?: string;
}

const ShimmerEffect: React.FC<ShimmerProps> = ({ 
  type = 'card', 
  count = 1,
  height = 'h-40'
}) => {
  const renderShimmer = () => {
    const shimmerItems = [];
    
    for (let i = 0; i < count; i++) {
      switch (type) {
        case 'banner':
          shimmerItems.push(
            <div key={i} className="w-full rounded-md overflow-hidden animate-pulse">
              <div className="bg-gray-300 h-64 w-full"></div>
            </div>
          );
          break;
        
        case 'video':
          shimmerItems.push(
            <div key={i} className="w-full rounded-md overflow-hidden animate-pulse p-2">
              <div className="bg-gray-300" style={{ aspectRatio: '16/9' }}></div>
              <div className="h-4 bg-gray-300 rounded mt-4 w-3/4 mx-auto"></div>
            </div>
          );
          break;
        
        case 'text':
          shimmerItems.push(
            <div key={i} className="w-full animate-pulse p-3">
              <div className="h-3 bg-gray-300 rounded mb-2"></div>
              <div className="h-3 bg-gray-300 rounded mb-2 w-5/6"></div>
              <div className="h-3 bg-gray-300 rounded w-4/6"></div>
            </div>
          );
          break;
        
        default: // card
          shimmerItems.push(
            <div key={i} className="border rounded-md overflow-hidden animate-pulse">
              <div className="bg-gray-200 h-12 rounded-t-md flex px-3 items-center">
                <div className="h-4 bg-gray-300 rounded w-1/3"></div>
              </div>
              <div className="p-4">
                <div className="grid grid-cols-2 mb-3">
                  <div className="bg-gray-300 h-24 rounded m-2"></div>
                  <div className="p-2">
                    <div className="h-3 bg-gray-300 rounded mb-2"></div>
                    <div className="h-3 bg-gray-300 rounded mb-2 w-5/6"></div>
                    <div className="h-3 bg-gray-300 rounded w-4/6"></div>
                  </div>
                </div>
                <div className="h-3 bg-gray-300 rounded mt-4 mb-2"></div>
                <div className="h-3 bg-gray-300 rounded mb-2 w-5/6"></div>
              </div>
            </div>
          );
      }
    }
    
    return shimmerItems;
  };

  return <>{renderShimmer()}</>;
};

export default ShimmerEffect; 