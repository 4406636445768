export const homeSection = {
  searches: [
    {
      name: "CASE LAW ADVANCE SEARCH",
      value: "/tiolsearch/caselawadvancesearch",
    },
    {
      name: "CASE LAW QUICK SEARCH",
      value: "/tiolsearch/caselawquicksearch",
    },
    {
      name: "NOTIFICATIONS/ CIRCULARS",
      value: "/tiolsearch/notifications_circulars",
    },
    { name: "COLUMN SEARCH", value: "/tiolsearch/columnsearch" },
  ],
  subscribes: [
    { name: "ALL IN ONE", value: "/all-in-one" },
    { name: "INDIRECT TAX", value: "/indirect-tax" },
    { name: "INCOME TAX", value: "/income-tax" },
    { name: "INTERNATIONAL TAXATION", value: "/international-taxation" },
    { name: "CORPORATE LAW", value: "/corporate-law" },
  ],
  userMenuSettings: [
    { name: "CHANGE PASSWORD", value: "/change-password" },
    { name: "BOOKMARK", value: "/bookmark" },
    { name: "LOGOUT", value: "/logout" },
  ],
};

export const commonStrings = {
  hindi: "Hindi",
  english: "English",
  noDataFound: "No data found",
  SIGNIN: "Sign In",
  SIGNUP: "Sign Up",
  FILTER: "Filter",
  CLEAR: "Clear",
  REGISTER: "Register As New User",
  FORGOT_PASSWORD: "Forgot Password",
  NEWS_UPDATE: "NEWS UPDATE",
  TIOL_APPS: "TIOL APPS",
  FOLLOW_US: "Follow us",
  UNION_BUDGET: "Union Budget",
  TOP_NEWS: "TOP NEWS",
  THE_POLICY_LAB: "THE POLICY LAB",
  VISION_360: "VISION 360",
  NoData: "No Data Available",
  DownloadPdf: "Download ",
  PrintDocument: "Print",
  Likes: "Likes",
  Bookmark: "Bookmark",
  NoDataSelect:
    "No data found for the selected criteria, please try with other criteria.",
  CHATCHAERROR: "Please enter the correct numeric captcha",
  ONCESELECT: "Please select at least one filter criteria",
  Captcha: "Captcha",
  successCreated: "Created Successfully.",
  successUpdated: "Updated Successfully.",
  SomethingWrong: "Something Want Wrong.Please try again",
  Infavour: "In Favour of",
  DOD: " Date of Decision",
  NEWSEARCH: "New Search",
  REFINESEARCH: "Refine Search",
  WHATSNEWGST: "WHAT'S NEW (GST)",
  WHATSNEWOTHER: "WHAT'S NEW (OTHERS)",
  TODAYSCASE: "TODAY'S CASE",
  ALREADYBOOKMARK: "This News  is already bookmarked",
  TIOL_INFO: "TIOL INFO",
  TIOL_GROUP: "a taxindiaonline website",
  COPYRIGHT: "copyright",
  PRIVACYPOLICY: "privacy policy",
  TIOLCOMPANY: "tiol private limited",
  caselawadvancesearch: "case law advance search Result",
  caselawquicksearch: "case law quick search result",
  notificationscirculars: "notifications circulars search result",
  columnsearch: "column search result",
  SUBSCRIPTION: "SUBSCRIPTION",
  SUBSCRIPTIONS: "SUBSCRIPTIONS",
  SUBSCRIPTION_REQUIRED: "You have not subscribed to this section. Please contact us to subscribe.",
  DISMISS: "Dismiss",
};
export const validationMsg = {
  captcha: "Enter a valid CAPTCHA",
  onceRequired: "Please fill out at least one field.",
  partyName: "Party Name is required.",
  keyword: "Keywords are required.",
  inFavour: "In Favour of is required.",
  law: "Law is required.",
  court: "Court is required.",
  tiolCitation: "Citation No. is required.",
  citYear: "Citation Year is required.",
  userNamePassword: "Either Username or Password is wrong",
  userDeActive:
    "The login credentials being used are either incorrect or inactive. Please contact TIOL\'s support if the issue persists.",
  userNameRequired: "User Name is mandatory",
  passwordRequired: "Password is mandatory",
  userNameAlreadyExist: "Username already used",
  alreadyRegister: "User already registered",
  registionFailed: "Registration failed. Please try again.",
  charOnly: "Alphabetical characters only",
  email: "Please enter a valid email",
  mobile: "Please enter a valid mobile number",
  password:
    "Password must contain 8 characters with at least one lowercase, one uppercase, one number, and one special character",
};

export const signIn = {
  userName: "User Name",
  password: "Password",
};
export const signUp = {
  userName: "User Name",
  password: "Password",
  fName: "First Name",
  mName: "Middle Name",
  lName: "Last Name",
  email: "Email",
  mobileNumber: "Mobile Number",
};

export const advanceSearch = {
  heading: "Case Law Advance Search",
  notes:
    '"It is not necessary to fill up all columns, search with at least 2 parameters will give you good results."',
  formStr: {
    partyName: "Party's Name",
    keyword: "Keyword",
    inFavour: "In Favour of",
    law: "Law",
    court: "Court",
    benchType: "Bench Type",
    benchCity: "Bench/City",
    tiolCition: "TIOL CITATION",
    tiolYear: "TIOL Year",
    dateOfDecision: "Date of Decision",
    assesmentYear: "Assessment Year",
  },
};

export const quickSearch = {
  heading: "Case Law Quick Search",
  searchBy: "Search By",
  notes: "",
  formStr: {
    partyName: "Party's Name",
    keyword: "Keyword",
    inFavour: "In Favour of",
    law: "Law",
    court: "Court",
    tiolCition: "TIOL CITATION",
  },
  benchType: "Bench Type",
  benchCity: "Bench/City",
  Captcha: "Captcha",
};

export const columnSearch = {
  heading: "Column Search",
  keyword: "Keyword",
  category: "Category",
};
export const notificationSearch = {
  heading: "Notifications/Circulars Search",
  form: {
    keyword: "Keyword",
    title: "Title/Header",
    orderNumber: "Cir/Not./Inst./Admin. Order No",
    category: "Category",
    subCategory: "Sub Category",
    subSubCategory: "Sub Category",
    subSubSubCategory: "Sub  Category",
    year: "Year",
  },
};

export const searchNames = {
  Notification: "notification",
  Advance: "advance",
  Column: "column",
  Quick: "quick",
};

export const commentPost = {
  comment: "Comment",
  postYourComment: "Post Your Comment",
  success: "Comment Successfully Created.",
  deleteSuccess: "Comment Successfully Deleted.",
  faild: "Spmething went wrong.please try again",
  readLess: "Read Less",
  readMore: "Read More",
  replies: "Replies...",
};
