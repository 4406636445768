import React, { Suspense, useEffect, useState } from 'react';
import MainHeader from '../../partial/MainHeader';
import DailyNews from '../News/News';
import { getApi } from '../../services/apiService';
import { apiConstant } from '../../services/apiConfig';
import MainFooter from '../../partial/Footer';
import { commonStrings } from '../../services/stringConstant';
import { Category } from '../../types/category.types';
import NewsCategory from '../News/Category';
import { DashboardSectionCategory } from '../../types/dashboardSectionCategory.types';
import AdComponent from '../googleAds';
import LoadingSpinner from '../LoadingSpinner';
import ShimmerEffect from '../ShimmerEffect';

interface LayoutProps {
    children: React.ReactNode;
    customClass?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, customClass }) => {
    const [commonData, setCommonData] = useState<Category[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        setIsLoading(true);
        getApi(apiConstant.sectionOne).then((res) => {
            setCommonData(res);
            setIsLoading(false);
        }).catch((e) => {
            console.log('====================================');
            console.log(e);
            console.log('====================================');
            setIsLoading(false);
        });

    }, []);

    const ShimmerLayoutWithNews = () => (
        <>
            <MainHeader />
            <div className='bg-[#dbdde1] py-2 container mx-auto px-3'>
                <div className='container flex justify-between mx-auto'>
                    <strong className="text-orangered">News Update</strong>
                </div>
                <div className="py-4">
                    <ShimmerEffect type="text" count={1} />
                </div>
            </div>
            <div className='p-2 md:grid md:grid-cols-12 gap-5 container mx-auto'>
                <div className={`md:col-span-8 col-span-12 px-2 ${customClass}`}>
                    <ShimmerEffect type="card" count={2} />
                </div>
                <div className='md:col-span-4 col-span-12'>
                    <ShimmerEffect type="card" count={1} />
                </div>
            </div>
            <MainFooter />
        </>
    );

    return (
        <>
            <Suspense fallback={<ShimmerLayoutWithNews />}>
                <MainHeader />
                <DailyNews data={commonData && commonData.find((item) => item.categoryName === commonStrings.NEWS_UPDATE)} />
                <div className='p-2 md:grid md:grid-cols-12 gap-5 container mx-auto'>
                    <div className={`md:col-span-8 col-span-12 px-2 ${customClass}`}>
                        {children}
                    </div>
                    <div className='md:col-span-4 col-span-12'>
                        {isLoading ? 
                            <ShimmerEffect type="card" count={1} /> :
                            <NewsCategory />
                        }
                    </div>
                </div>

                <MainFooter />
            </Suspense>
        </>
    );
};

export default Layout;
